import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import Header from "../components/header"

const FaqPage = () => {
  return <Layout title="FAQ">
    <Header title="FAQ" />

    <section className="text-center py-5 mt-5">
      <Container>
        <Row>
          <Col xs={10} className="text-start">
            <h5 style={{fontWeight: 700}}>What games do you offer?</h5>
            <p className="mb-4">Please check out our Game Menu to see our current games available.</p>

            <h5 style={{fontWeight: 700}}>How do I schedule?</h5>
            <p className="mb-4">Click the Request Booking button in the top right of the screen. After your reservation request is submitted, we will get back to you within 24 hours.</p>
            
            <h5 style={{fontWeight: 700}}>How do I contact Grass Top Games with a question?</h5>
            <p className="mb-4">You can reach us by email at <a href="mailto:hello@grasstopgames.com">hello@grasstopgames.com</a> or text us at (630) 999-8020.</p>
            
            <h5 style={{fontWeight: 700}}>How much is delivery?</h5>
            <p className="mb-4">Delivery is based upon your location. Our current delivery/pick up fee for Wheaton and Glen Ellyn (School District boundaries 200 &amp; 89) is $25. If you'd like a reservation outside those locations, please contact us.</p>
            
            <h5 style={{fontWeight: 700}}>What is your cancellation policy?</h5>
            <p className="mb-4">In the event the customer needs to cancel, we need to be notified through email by 10AM the day before the rental in order to receive a 100% refund.</p>
            <p className="mb-4">Any cancellation after 10AM the day before the rental will not receive a refund.</p>
            
            <h5 style={{fontWeight: 700}}>Can I modify my booking after I've already paid?</h5>
            <p className="mb-4">We would be happy to help, just send us an email.</p>
            
            <h5 style={{fontWeight: 700}}>What if the game gets damaged or lost?</h5>
            <p className="mb-4">Games will be assessed for damage when we pick them up. Customers can be present during the inspection.</p>
            <p className="mb-4">Games that are damaged, beyond normal wear and tear, will be charged a damage fee.</p>
            <p className="mb-4">Games that are lost or missing pieces will be charged a replacement fee.</p>
          </Col>
        </Row>

    
      </Container>

    </section>

  </Layout>
}

export default FaqPage
